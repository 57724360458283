<template>
    <OModal ref="modal" @show="onShow" @shown="onShown" @hide="onHide">
        <div class="modal-dialog modal-lg"  >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5">{{ $t('Share with OrgUnits') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="max-height: 600px;">
                <ODataGrid :dataObject = "dsSharedWith" hideGridMenu hideMultiselectColumn>
                    <OColumn name="OrgUnit_Name" :headerName="$t('Org Unit')" :editable="row => row.isNewRecord" width="400px">
                        <template #editor="{ row }">
                            <OrgunitLookup textInput :value="row.OrgUnit_Name" :bind="(sel) => { row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name }" />
                        </template>
                    </OColumn>
                    <OColumn field="IncludeSubOrgUnits" width="200">
                        <template #default="{row}">
                            <input type="checkbox" v-model="row.IncludeSubOrgUnits">
                        </template>
                    </OColumn>
                </ODataGrid>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
import type FilterItem from 'o365.modules.FilterItem.ts';
import OModal from 'o365.vue.components.Modal.vue';
import OrgunitLookup from 'o365.vue.components.OrgUnitsLookup.vue';
import { getSharedWithOrgUnitsDO } from 'o365.modules.filters.store.ts'
import { ref } from 'vue';

const props = defineProps<{
    filterItem: FilterItem
}>();

const emit = defineEmits<{
    (e: 'show'):void
    (e: 'shown'):void
    (e: 'hide'):void
}>();

const modal = ref<OModal|null>(null);

const dsSharedWith = getSharedWithOrgUnitsDO();

function onShow() {
    emit('show');
}

function onShown(e) {
    if (props.filterItem.id || props.filterItem.ID) {
        dsSharedWith.fields.Filter_ID.defaultValue = props.filterItem.id || props.filterItem.ID;
        dsSharedWith.recordSource.whereClause = `[Filter_ID] = '${props.filterItem.id ?? props.filterItem.ID}'`;
        dsSharedWith.load();
    }
    emit('shown');
}

function onHide() {
    dsSharedWith.storage.clearItems();
    dsSharedWith.dynamicLoading.dataLoaded([]);
    emit('hide');
}

function show() {
    if (modal.value?.show) {
        modal.value.show();
    }
}

function hide() {
    if (modal.value?.hide) {
        modal.value.hide();
    }
}

defineExpose({ modal, show, hide });

</script>